import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`2018`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.pinteratthepinter.com/the-plays/the-room-victoria-station-family-voices"
      }}>{`Pinter Five`}</a>{` — Bert and others`}</p>
    <h3>{`2006`}</h3>
    <p>{`The Exonerated — Kerry`}</p>
    <h3>{`2004`}</h3>
    <p>{`Dumb Show — Greg`}</p>
    <h3>{`2003`}</h3>
    <p>{`A Woman Of No Importance — Lord Illingworth`}</p>
    <h3>{`2002`}</h3>
    <p>{`The Elephant Man — Dr. Frederick Treves`}</p>
    <h3>{`2001`}</h3>
    <p>{`Speak Truth To Power: Voices From Beyond The Dark — Third Voice`}</p>
    <p>{`The Caretaker — Mick`}</p>
    <h3>{`1999`}</h3>
    <p>{`Closer — Dan`}</p>
    <h3>{`1998`}</h3>
    <p>{`The Iceman Cometh — Don Parrit`}</p>
    <p>{`Hurlyburly — Eddie`}</p>
    <h3>{`1996`}</h3>
    <p>{`Les Enfants Du Paradis — Baptiste`}</p>
    <h3>{`1995`}</h3>
    <p>{`Design For Living — Otto`}</p>
    <h3>{`1992`}</h3>
    <p>{`A Midsummer Night's Dream — Lysander`}</p>
    <h3>{`1991`}</h3>
    <p>{`The Pitchfork Disney — Presley Straw`}</p>
    <h3>{`1989`}</h3>
    <p>{`The History Of Tom Jones — Tom Jones`}</p>
    <p>{`A Madhouse In Goa — David, Barnaby Grace`}</p>
    <h3>{`1988`}</h3>
    <p>{`'Tis Pity She's A Whore — Giovanni`}</p>
    <h3>{`1987`}</h3>
    <p>{`Candida — Marchbanks`}</p>
    <p>{`The Importance Of Being Earnest — Algernon Moncrieff`}</p>
    <h3>{`1986`}</h3>
    <p>{`Amadeus — Wolfgang Amadeus Mozart`}</p>
    <h3>{`1985`}</h3>
    <p>{`Torch Song Trilogy — Alan`}</p>
    <h3>{`1984`}</h3>
    <p>{`Sufficient Carbohydrate — Clayton Vosper`}</p>
    <h3>{`1983`}</h3>
    <p>{`The Killing Of Mr. Toad — Alistair Graham, Mr. Toad`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      